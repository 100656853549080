import React, { useState, useEffect, useContext, useRef } from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/styles/makeStyles'
import PageContext from '@context'
import useStickyFilter from '@hooks/use-sticky-filter'
import ContentContainer from '@system/content-container'
import ResourceCard from '@system/resource-card'
import isInBrowser from '@helpers/is-in-browser'
import classNames from 'classnames'
import get from 'lodash/get'
import fetchFilteredStories from '@helpers/fetch-filtered-stories'
import { imgMaxWidths } from '@variables/global-variables'

const useStyles = makeStyles((theme) => ({
  // Filter
  filterBarOuterContainer: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      zIndex: 1100,
      '&:before': {
        background: theme.palette.background.paper,
        boxShadow:
          '0px 2px 2px -1px rgba(0,0,0,0.2), 0px 4px 3px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)',
        content: '""',
        height: '100%',
        left: '0',
        marginLeft: '50%',
        position: 'absolute',
        opacity: '0',
        top: '0',
        transform: 'translateX(-50%)',
        transition: '0.2s opacity, 0.2s visibility',
        visibility: 'hidden',
        width: 'var(--filter-bar-width)',
        zIndex: '-1',
      },
    },
  },
  filterBarOuterContainerSticky: {
    [theme.breakpoints.up('md')]: {
      '&:before': {
        opacity: '1',
        visibility: 'visible',
      },
    },
  },
  // Stories
  storiesOuterContainer: { marginTop: '40px' },
  storiesInnerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    width: 'calc(100% + 16px)',
    margin: '-8px',
  },
  storiesCard: {
    flexGrow: 0,
    maxWidth: '33.333333%',
    flexBasis: '33.333333%',
    padding: '8px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
}))

const CustomersFilter = (props) => {
  const classes = useStyles()
  const pageContext = useContext(PageContext)
  const customerItemsRef = useRef()
  const stickyContainerRef = useRef()

  const isSticky = useStickyFilter(stickyContainerRef.current)

  const {
    blok,
    blok: { filtersMenu },
    isCustomersModule,
    updateHiddenFieldsValue,
  } = props

  const numberOfCustomerStoriesToLoadOnScroll = 12
  const numberOfCustomerStoriesToLoadInitially = 12

  const [customerFilteredStories, setCustomerFilteredStories] = useState([])
  const [filters, setFilters] = useState([
    {
      filterBy: '',
      filterSelect: '',
    },
  ])
  const [filterLogicRules, setFilterLogicRules] = useState([
    {
      filterResultLogic: '',
      multiValueLogic: '',
      filterDataPath: '',
    },
  ])
  const [isVideoView, setIsVideoView] = useState(false)
  const [numberOfCustomerStoriesToLoad, setNumberOfCustomerStoriesToLoad] =
    useState(numberOfCustomerStoriesToLoadInitially)
  const [searchValue, setSearchValue] = useState(null)

  const filteredMenuList = get(filtersMenu, '[0].filtersList')

  let locationHref
  let locationSearch

  if (isInBrowser) {
    locationHref = pageContext.location
      ? pageContext.location.origin + pageContext.location.pathname
      : document.location.href
    locationSearch = pageContext.location
      ? pageContext.location.search
      : document.location.search
  }

  const customerStories =
    pageContext.customerStories &&
    pageContext.customerStories
      .filter(
        (customerStory) => customerStory.content.component !== 'customersIndex'
      )
      .map((customerStory) => {
        return { ...customerStory, ...customerStory.content }
      })

  const formattedCustomerStory = {
    firmType: '',
    capabilities: '',
    products: '',
    valueDriver: '',
    featuredLocale: '',
  }

  const emptyFilter = [
    {
      filterBy: '',
      filterSelect: '',
    },
  ]

  const emptyFilterLogic = [
    { filterResultLogic: '', multiValueLogic: '', filterDataPath: '' },
  ]

  const compareGalleryItems = (a, b) => {
    return a.companyName <= b.companyName ? -1 : 1
  }

  useEffect(() => {
    setFilters(filterByParam)
    setFilterLogicRules(filterSelectParam)
  }, [])

  useEffect(() => {
    const filterDataPath = filters.map((filter) => filter.filterBy)
    if (!searchValue) {
      if (isFilterSelected) {
        const paramsString = new URL(locationHref)
        const filterParam = filters.filter(
          (filter) => filter.filterSelect.length > 0
        )
        filterParam.forEach((param) => {
          if (param.filterBy !== '') {
            paramsString.searchParams.append(param.filterBy, param.filterSelect)
          }
        })
        window.history.pushState({}, '', paramsString.href)
      } else if (locationSearch.includes('video')) {
        setIsVideoView(true)
      } else {
        window.history.pushState(
          {},
          '',
          pageContext.path || document.location.href
        )
      }
    }
    setFilterLogicRules(getUpdatedFilterLogicRules(filterDataPath))
  }, [filters])

  useEffect(() => {
    if (isFilterSelected || searchValue) {
      const filteredStories = fetchFilteredStories(
        {
          ...formattedCustomerStory,
          filterLogicRules: filterLogic,
        },
        customerStories,
        ...searchValue
      )?.sort(compareGalleryItems)

      setCustomerFilteredStories(filteredStories)
    } else if (locationSearch.includes('video')) {
      setIsVideoView(true)
    } else {
      setCustomerFilteredStories(null)
    }
  }, [filters])

  useEffect(() => {
    const shouldUpdateHiddenFieldsValue = isFilterSelected || searchValue
    updateHiddenFieldsValue(shouldUpdateHiddenFieldsValue)
  }, [filters, searchValue])

  useEffect(() => {
    if (isVideoView) {
      const filteredVideoStories = customerStories.filter(
        (story) =>
          story.wistiaVideo?.length > 0 ||
          story.body?.filter(
            (bodyBlok) => bodyBlok.component === 'wistiaVideoModule'
          ).length > 0
      )

      setCustomerFilteredStories(filteredVideoStories)
      updateHiddenFieldsValue(true)
    }
  }, [isVideoView])

  const onScrollCustomersPage = () => {
    const customersBottomRect =
      customerItemsRef.current.getBoundingClientRect().bottom

    if (
      customersBottomRect <= window.innerHeight &&
      customerStories &&
      numberOfCustomerStoriesToLoad <= customerStories.length
    ) {
      setNumberOfCustomerStoriesToLoad(
        numberOfCustomerStoriesToLoad + numberOfCustomerStoriesToLoadOnScroll
      )
    }
  }

  useEffect(() => {
    if (isInBrowser) {
      window.addEventListener('scroll', onScrollCustomersPage)
    }

    return () => {
      if (isInBrowser) {
        window.removeEventListener('scroll', onScrollCustomersPage)
      }
    }
  }, [numberOfCustomerStoriesToLoad])

  const filterByParam = []
  const filterSelectParam = []
  if (isInBrowser) {
    const href = pageContext.location
      ? pageContext.location.href
      : document.location.href
    const paramsString = new URL(href)

    for (const keys of paramsString.searchParams.keys()) {
      const hasFilteredParams = filteredMenuList.some(
        (filterMenu) => filterMenu.filterDataPath === keys
      )

      if (keys !== 'path' && keys.indexOf('_storyblok') < 0) {
        if (keys === 'all') {
          filterByParam.push({
            filterBy: 'sortBy',
            filterSelect: 'reverse-chronological',
          })
          filterSelectParam.push({
            filterResultLogic: '',
            multiValueLogic: '',
            filterDataPath: '',
          })
        } else if (hasFilteredParams) {
          const filterLogicRules = filteredMenuList.filter(
            (filterMenu) => filterMenu.filterDataPath === keys
          )
          const filterResultLogic = get(
            filterLogicRules,
            '[0].filterResultsLogic',
            'broadensResults'
          )

          const multipleValueLogic = get(
            filterLogicRules,
            '[0].multipleValuesLogic',
            'narrowsResults'
          )
          filterByParam.push({
            filterBy: keys,
            filterSelect: paramsString.searchParams.getAll(keys)[0].split(','),
          })

          filterSelectParam.push({
            filterResultLogic: filterResultLogic,
            multiValueLogic: multipleValueLogic,
            filterDataPath: keys,
          })
        }
      }
    }
  }

  const getUpdatedFilterLogicRules = (filterDataPath) =>
    filterLogicRules.filter(
      (filterLogic) => filterDataPath.indexOf(filterLogic.filterDataPath) !== -1
    )

  const isFilterSelected = filters.some(
    (eachFilter) =>
      eachFilter.filterSelect && eachFilter.filterSelect.length > 0
  )

  const onChangeFilters = (event, filterName) => {
    const emptyString = ''
    const value = event.target.value || emptyString

    setSearchValue(null)

    if (value === emptyString) {
      setFilters((oldFilters) =>
        oldFilters.filter((item) => item.filterBy !== filterName)
      )
      setFilterLogicRules((oldFilterLogicRules) =>
        oldFilterLogicRules.filter(
          (filterResultLogic) => filterResultLogic.filterDataPath !== filterName
        )
      )
    } else {
      setFilters((oldFilters) => {
        const isOldFilterMatched = oldFilters.some(
          (filter) => filter.filterBy === filterName
        )
        return isOldFilterMatched
          ? oldFilters.map((filter) => {
              if (filter.filterBy === filterName) filter.filterSelect = value
              return filter
            })
          : [...oldFilters, { filterBy: filterName, filterSelect: value }]
      })
    }
  }

  const resetFilters = () => {
    window.history.pushState({}, '', pageContext.path || document.location.href)
    setSearchValue(null)
    setFilters(emptyFilter)
    setFilterLogicRules(emptyFilterLogic)
    updateHiddenFieldsValue(false)
  }

  const fetchFilterLogicRules = (
    filterResultLogic,
    multiValueLogic,
    filterDataPath
  ) => {
    setFilterLogicRules((oldFilterLogicRules) => {
      const haveOldFiltersMatched = oldFilterLogicRules.some(
        (filterResultLogic) =>
          filterResultLogic.filterDataPath === filterDataPath
      )
      return haveOldFiltersMatched
        ? [...oldFilterLogicRules]
        : [
            ...oldFilterLogicRules,
            {
              filterResultLogic: filterResultLogic,
              multiValueLogic: multiValueLogic,
              filterDataPath: filterDataPath,
            },
          ]
    })
  }

  const onChangeSearchText = (event) => {
    const searchedText = event.target.value
    setSearchValue(searchedText)
    setFilters([
      {
        filterBy: '',
        filterSelect: '',
      },
    ])
    setFilterLogicRules([
      { filterResultLogic: '', multiValueLogic: '', filterDataPath: '' },
    ])
  }

  filters.forEach(
    (filter) =>
      (formattedCustomerStory[`${filter.filterBy}`] = filter.filterSelect)
  )

  const filterLogic = filterLogicRules.some(
    (filterLogic) => filterLogic.filterDataPath !== ''
  )
    ? filterLogicRules
    : filterSelectParam

  const getStoryLink = (title) => {
    let matchedCustomerStories =
      !!pageContext.customerStories &&
      pageContext.customerStories.filter(
        (story) => story.content.title === title
      )
    return get(matchedCustomerStories, '[0].full_slug', '')
  }

  return (
    <SbEditable content={blok}>
      <Box
        className={classNames(classes.filterBarOuterContainer, {
          [classes.filterBarOuterContainerSticky]: isSticky,
        })}
        ref={stickyContainerRef}
      >
        {!!filtersMenu &&
          filtersMenu.map((filterMenu) => (
            <ContentContainer key={filtersMenu._uid}>
              {renderBlok({
                ...filterMenu,
                filters,
                onChangeFilters,
                onChangeSearchText,
                fetchFilterLogicRules,
                searchValue,
                resetFilters,
                isCustomersModule,
              })}
            </ContentContainer>
          ))}
      </Box>
      <ContentContainer>
        <Box className={classes.storiesOuterContainer} ref={customerItemsRef}>
          <Box className={classes.storiesInnerContainer}>
            {!!customerFilteredStories &&
              !!customerFilteredStories.length > 0 &&
              customerFilteredStories
                .slice(0, numberOfCustomerStoriesToLoad)
                .map((story) => {
                  const isVideoStory =
                    story.wistiaVideo?.length > 0 ||
                    story.body.filter(
                      (bodyBlok) => bodyBlok.component === 'wistiaVideoModule'
                    ).length > 0

                  const stories = {
                    alignment: 'left',
                    description: story.subtitle,
                    image: story.image,
                    noDescMargin: false,
                    notVisibleOnMobile: false,
                    title: story.title,
                    resourceReference: true,
                    format: 'Customer Story',
                    link: story.full_slug || getStoryLink(story.title),
                    textToHyperlink: isVideoStory ? 'Watch' : 'Read',
                  }
                  return (
                    <Box className={classes.storiesCard} key={story.id}>
                      <ResourceCard
                        blok={stories}
                        imgMaxWidth={imgMaxWidths.resourceCard}
                      />
                    </Box>
                  )
                })}
          </Box>
        </Box>
      </ContentContainer>
    </SbEditable>
  )
}

export default CustomersFilter
